import React, { Component } from 'react';
import { ResponsiveContainer, BarChart, XAxis, Tooltip, Legend, Bar } from 'recharts';

class Expenses extends Component {
    render() {
        const data = [
            {
              "name": "Enero",
              "uv": 4000,
              "pv": 2400,
              "amt": 2400
            },
            {
              "name": "Febrero",
              "uv": 3000,
              "pv": 1398,
              "amt": 2210
            },
            {
              "name": "Marzo",
              "uv": 2000,
              "pv": 9800,
              "amt": 2290
            },
            {
              "name": "Abril",
              "uv": 2780,
              "pv": 3908,
              "amt": 2000
            },
            {
              "name": "Mayo",
              "uv": 1890,
              "pv": 4800,
              "amt": 2181
            },
            {
              "name": "Junio",
              "uv": 2390,
              "pv": 3800,
              "amt": 2500
            },
            {
              "name": "Julio",
              "uv": 3490,
              "pv": 4300,
              "amt": 2100
            }
        ]
        return (
            <div className="expenses">
                <ResponsiveContainer width="100%" height={250}>
                    <BarChart width={730} height={250} data={data}>
                        <XAxis dataKey="name" />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="uv" fill="#f24464" />
                    </BarChart>
                </ResponsiveContainer>
                <ul>
                    <li>
                        <i className="material-icons">directions_car</i>
                        <div className="container">
                            <h3>Transporte</h3>
                            <span>3 transacciones</span>
                        </div>
                        <p>$8,570<small>.17</small></p>
                    </li>
                    <li>
                        <i className="material-icons">fastfood</i>
                        <div className="container">
                            <h3>Alimento</h3>
                            <span>3 transacciones</span>
                        </div>
                        <p>$8,570<small>.17</small></p>
                    </li>
                    <li>
                        <i className="material-icons">business_center</i>
                        <div className="container">
                            <h3>Seguro</h3>
                            <span>3 transacciones</span>
                        </div>
                        <p>$8,570<small>.17</small></p>
                    </li>
                    <li>
                        <i className="material-icons">domain</i>
                        <div className="container">
                            <h3>Servicios</h3>
                            <span>3 transacciones</span>
                        </div>
                        <p>$8,570<small>.17</small></p>
                    </li>
                    <li>
                        <i className="material-icons">beach_access</i>
                        <div className="container">
                            <h3>Entretenimiento</h3>
                            <span>3 transacciones</span>
                        </div>
                        <p>$8,570<small>.17</small></p>
                    </li>
                    <li>
                        <i className="material-icons">monetization_on</i>
                        <div className="container">
                            <h3>Inversiones</h3>
                            <span>3 transacciones</span>
                        </div>
                        <p>$8,570<small>.17</small></p>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Expenses;