import * as firebase from 'firebase';

firebase.initializeApp({
    apiKey: "AIzaSyBWkiBdHEtPPB1u2cD-A11y9SrrQ87y3-U",
    authDomain: "monuu-87aa3.firebaseapp.com",
    databaseURL: "https://monuu-87aa3.firebaseio.com",
    projectId: "monuu-87aa3",
    storageBucket: "monuu-87aa3.appspot.com",
    messagingSenderId: "248519686022"
});

export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const storage = firebase.storage();