import React, { Component } from 'react';
import { auth } from '../firebase';

class LogIn extends Component {
    handleSubmit(e) {
        e.preventDefault();
        const email = this.email.value;
        const password = this.password.value;

        if (email && password) {
            const logIn = auth.signInWithEmailAndPassword(email,password);
            logIn.then(() => {
                alert('Sesión Iniciada')
            }).catch(error => {
                alert('Error al iniciar sesión, intentalo nuevamente: ' + error)
            });
        } else {
            alert('Completa todos los campos para continuar');
        }
    }
    render() {
        return (
            <div className="log-in">
                <form onSubmit={e => this.handleSubmit(e)}>
                    <div className="input-container">
                        <label>Email</label>
                        <input ref={input => this.email = input} placeholder="Email" type="email"/>
                    </div>
                    <div className="input-container">
                        <label>Contraseña</label>
                        <input ref={input => this.password = input} placeholder="Contraseña" type="password" />
                    </div>
                    <div className="input-container">
                        <button type="submit">Entrar</button>
                    </div>
                </form>
            </div>
        )
    }
}

export default LogIn;