import React, { Component } from 'react';

class Payments extends Component {
    render() {
        return (
            <div className="payments">
                    <ul>
                        <li>
                            <i className="material-icons">send</i>
                            <div className="container">
                                <h3>Transferencia Bancaria</h3>
                                <span>Envía dinero a otras cuentas monuu o cuentas de otros bancos</span>
                            </div>
                        </li>
                        <li>
                            <i className="material-icons">watch_later</i>
                            <div className="container">
                                <h3>Programar Pago</h3>
                                <span>Programa todos tus pagos de forma automática</span>
                            </div>
                        </li>
                        <li>
                            <i className="material-icons">gps_fixed</i>
                            <div className="container">
                                <h3>Contactos cercanos</h3>
                                <span>Envía o recibe dinero de tus contactos más cercanos</span>
                            </div>
                        </li>
                    </ul>
                    <div className="payments__container">
                        <h2>Envía o recibe dinero con MONUU</h2>
                        <ul>
                            <li>
                                <i className="material-icons">add</i>
                                <h3>Agregar Contacto</h3>
                            </li>
                            <li>
                                <picture>
                                    <img src="/images/profile1.jpg" alt="Profile 1"/>
                                </picture>
                                <h3>Fernanda</h3>
                            </li>
                            <li>
                                <picture>
                                    <img src="/images/profile2.jpg" alt="Profile 1"/>
                                </picture>
                                <h3>Jesús</h3>
                            </li>
                            <li>
                                <picture>
                                    <img src="/images/profile3.jpg" alt="Profile 1"/>
                                </picture>
                                <h3>Daniel</h3>
                            </li>
                            <li>
                                <picture>
                                    <img src="/images/profile4.jpg" alt="Profile 1"/>
                                </picture>
                                <h3>Antonio</h3>
                            </li>
                            <li>
                                <picture>
                                    <img src="/images/profile1.jpg" alt="Profile 1"/>
                                </picture>
                                <h3>Fernanda</h3>
                            </li>
                            <li>
                                <picture>
                                    <img src="/images/profile2.jpg" alt="Profile 1"/>
                                </picture>
                                <h3>Jesús</h3>
                            </li>
                            <li>
                                <picture>
                                    <img src="/images/profile3.jpg" alt="Profile 1"/>
                                </picture>
                                <h3>Daniel</h3>
                            </li>
                            <li>
                                <picture>
                                    <img src="/images/profile4.jpg" alt="Profile 1"/>
                                </picture>
                                <h3>Antonio</h3>
                            </li>
                        </ul>
                        <form>

                        </form>
                    </div>
            </div>
        )
    }
}

export default Payments;