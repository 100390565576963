import React, { Component } from 'react';
import { ResponsiveContainer, AreaChart, XAxis, Tooltip, Area } from 'recharts';

class Home extends Component {
    render() {
        const data = [
            {
              "name": "Enero",
              "uv": 4000,
              "pv": 2400,
              "amt": 2400
            },
            {
              "name": "Febrero",
              "uv": 3000,
              "pv": 1398,
              "amt": 2210
            },
            {
              "name": "Marzo",
              "uv": 2000,
              "pv": 9800,
              "amt": 2290
            },
            {
              "name": "Abril",
              "uv": 2780,
              "pv": 3908,
              "amt": 2000
            },
            {
              "name": "Mayo",
              "uv": 1890,
              "pv": 4800,
              "amt": 2181
            },
            {
              "name": "Junio",
              "uv": 2390,
              "pv": 3800,
              "amt": 2500
            },
            {
              "name": "Julio",
              "uv": 3490,
              "pv": 4300,
              "amt": 2100
            }
        ]
        return (
            <div className="home">
                <ResponsiveContainer width="100%" height={250}>
                    <AreaChart data={data}margin={{ top: 20, right: 0, left: 0, bottom: 20 }}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#f24464" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#f24464" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#0f2f59" stopOpacity={0.8}/>
                            <stop offset="95%" stopColor="#0f2f59" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name" />
                        <Tooltip />
                        <Area type="monotone" dataKey="uv" stroke="#f24464" fillOpacity={1} fill="url(#colorUv)" />
                        <Area type="monotone" dataKey="pv" stroke="#0f2f59" fillOpacity={1} fill="url(#colorPv)" />
                    </AreaChart>
                </ResponsiveContainer>
                <div className="home__nav">
                    <ul>
                        <li className="active">Todos</li>
                        <li>Ingresos</li>
                        <li>Egresos</li>
                    </ul>
                    <div className="home__nav-search">
                        <i className="material-icons">search</i>
                        <input type="text"/>
                    </div>
                </div>
                <ul className="home__container">
                    <li>
                        <div className="content">
                            <h4>Gasto de prueba 1</h4>
                            <span>Comentarios de gasto de prueba 1</span>
                        </div>
                        <p className="red">-$2,500<small>.00</small></p>
                        <button>
                            <i className="material-icons">zoom_out_map</i>
                        </button>
                    </li>
                    <li>
                        <div className="content">
                            <h4>Gasto de prueba 2</h4>
                            <span>Comentarios de gasto de prueba 2</span>
                        </div>
                        <p className="red">-$500<small>.00</small></p>
                        <button>
                            <i className="material-icons">zoom_out_map</i>
                        </button>
                    </li>
                    <li>
                        <div className="content">
                            <h4>Ingreso de prueba 1</h4>
                            <span>Comentarios de ingreso de prueba 1</span>
                        </div>
                        <p className="green">$7,500<small>.00</small></p>
                        <button>
                            <i className="material-icons">zoom_out_map</i>
                        </button>
                    </li>
                    <li>
                        <div className="content">
                            <h4>Gasto de prueba 3</h4>
                            <span>Comentarios de gasto de prueba 3</span>
                        </div>
                        <p className="red">-$1,200<small>.00</small></p>
                        <button>
                            <i className="material-icons">zoom_out_map</i>
                        </button>
                    </li>
                    <li>
                        <div className="content">
                            <h4>Ingreso de prueba 2</h4>
                            <span>Comentarios de ingreso de prueba 2</span>
                        </div>
                        <p className="green">$5,000<small>.00</small></p>
                        <button>
                            <i className="material-icons">zoom_out_map</i>
                        </button>
                    </li>
                </ul>
            </div>
        )
    }
}

export default Home;