import React, { Component } from 'react';

class Help extends Component {
    constructor() {
        super();
        this.state = {
            messages: [
                {
                    from: 'Monuu Bot',
                    img: 'https://avatars.slack-edge.com/2017-09-08/239515915463_33e7bbe532d1ec07802e_512.png',
                    message: 'Hola David! Cómo puedo ayudarte?'
                }
            ]
        }
    }
    renderItem(key,list) {
        const item = list[key];
        if (item.from === 'Monuu Bot') {
            return (
                <li key={key}>
                    <picture>
                        <img src={item.img} alt={item.from}/>
                    </picture>
                    <div className="container">
                        <small>{item.from} dice:</small>
                        <p>{item.message}</p>
                    </div>
                </li>
            )
        } else {
            return (
                <li className="right" key={key}>
                    <picture>
                        <img src={item.img} alt={item.from}/>
                    </picture>
                    <div className="container">
                        <small>{item.from} dice:</small>
                        <p>{item.message}</p>
                    </div>
                </li>
            )
        }
    }
    validator(list) {
        if (list) {
            return Object.keys(list).map(key => this.renderItem(key,list))
        }
    }
    handleSubmit(e) {
        e.preventDefault();
        const message = this.message.value;
        var messages = this.state.messages;
        messages.push({
            from:'David Sacal',
            img: 'https://instagram.fmex10-1.fna.fbcdn.net/vp/40f04a3cf777838fe90643dd22250017/5D4C8BF6/t51.2885-19/s320x320/33110054_1926103464347927_2112517868271697920_n.jpg?_nc_ht=instagram.fmex10-1.fna.fbcdn.net',
            message
        });
        this.setState({ messages })
        this.addForm.reset();
    }
    render() {
        return (
            <div className="help">
                <div className="help__container">
                    <ul>
                        {this.validator(this.state.messages)}
                    </ul>
                    <form ref={input => this.addForm = input} onSubmit={e => this.handleSubmit(e)}>
                        <textarea ref={input => this.message = input} rows="10"></textarea>
                        <button><i className="material-icons">send</i></button>
                    </form>
                </div>
            </div>
        )
    }
}

export default Help;