import React, { Component } from 'react';
import { auth } from '../firebase';
import { Link, Switch, Route } from 'react-router-dom';

import Home from './home/Home';
import Expenses from './expenses/Expenses';
import LogIn from './LogIn';
import Payments from './payments/Payments';
import Help from './help/Help';

class App extends Component {
    constructor() {
        super();
        this.state = {
            isLogged: false,
            isFrozen: false
        }
    }
    componentDidMount() {
        window.scrollTo(0,0);
        auth.onAuthStateChanged(user => {
            if (user) {
                this.setState({
                    isLogged: true
                })
            } else {
                this.setState({
                    isLogged: false
                })
            }
        });
    }
    handleLogOut(){
        auth.signOut();
    }
    render() {
        if (this.state.isLogged === true) {
            return (
                <div className="app">
                    <div className="app__side">
                        <div className="app__side-top">
                            <picture>
                                <img src="/images/logo.png" alt="Monuu"/>
                            </picture>
                        </div>
                        <div className="app__side-container">
                            <h2>Saldo actual</h2>
                            <p>$977,230<small>.28</small></p>
                            <div className="app__side-buttons">
                                <button>Realizar Pago</button>
                                <button>Solicitar Pago</button>
                                <button>Recargar Cuenta</button>
                            </div>
                        </div>
                        <div className="app__side-actions">
                            <h2>Mi cuenta</h2>
                            <picture>
                                {this.state.isFrozen === false ? <img src="/images/card.png" alt="Tarjeta Monuu"/> : <img src="/images/frost-card.png" alt="Tarjeta Monuu"/>}
                            </picture>
                            <div className="buttons-container">
                                <button onClick={() => this.setState({ isFrozen: !this.state.isFrozen })}>{this.state.isFrozen === false ? 'Congelar' : 'Descongelar'} Tarjeta</button>
                                <button>Ver Código de Seguridad</button>
                            </div>
                        </div>
                    </div>
                    <main>
                        <header>
                            <ul className="header__container">
                                <Link to="/">Inicio</Link>
                                <Link to="/gastos">Gastos</Link>
                                {/* <Link to="/cuenta">Cuenta</Link> */}
                                <Link to="/pagos">Pagos</Link>
                                <Link to="/ayuda">Ayuda</Link>
                                {/* <Link to="/configuracion">Configuración</Link> */}
                                <i onClick={this.handleLogOut} className="material-icons">exit_to_app</i>
                            </ul>
                        </header>
                        <Switch>
                            <Route component={Home} path="/" exact={true} />
                            <Route component={Expenses} path="/gastos" exact={true} />
                            <Route component={Payments} path="/pagos" exact={true} />
                            <Route component={Help} path="/ayuda" exact={true} />
                        </Switch>
                    </main>
                </div>
            )
        } else {
            return <LogIn/>
        }
    }
}

export default App;